
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//budgets routes
			{
				path: '/budgets/:fieldName?/:fieldValue?',
				name: 'budgetslist',
				component: () => import('./pages/budgets/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/budgets/view/:id', 
				name: 'budgetsview', 
				component: () => import('./pages/budgets/view.vue'), 
				props: true
			},
		
			{ 
				path: '/budgets/add', 
				name: 'budgetsadd', 
				component: () => import('./pages/budgets/add.vue'), 
				props: true
			},
	
			{ 
				path: '/budgets/edit/:id', 
				name: 'budgetsedit', 
				component: () => import('./pages/budgets/edit.vue'), 
				props: true
			},
		

//categories routes
			{
				path: '/categories/:fieldName?/:fieldValue?',
				name: 'categorieslist',
				component: () => import('./pages/categories/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/categories/view/:id', 
				name: 'categoriesview', 
				component: () => import('./pages/categories/view.vue'), 
				props: true
			},
		
			{ 
				path: '/categories/add', 
				name: 'categoriesadd', 
				component: () => import('./pages/categories/add.vue'), 
				props: true
			},
	
			{ 
				path: '/categories/edit/:id', 
				name: 'categoriesedit', 
				component: () => import('./pages/categories/edit.vue'), 
				props: true
			},
		

//documents routes
			{
				path: '/documents/:fieldName?/:fieldValue?',
				name: 'documentslist',
				component: () => import('./pages/documents/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/documents/view/:id', 
				name: 'documentsview', 
				component: () => import('./pages/documents/view.vue'), 
				props: true
			},
		
			{ 
				path: '/documents/add', 
				name: 'documentsadd', 
				component: () => import('./pages/documents/add.vue'), 
				props: true
			},
	
			{ 
				path: '/documents/edit/:id', 
				name: 'documentsedit', 
				component: () => import('./pages/documents/edit.vue'), 
				props: true
			},
//documents routes
			{
				path: '/images/:fieldName?/:fieldValue?',
				name: 'imageslist',
				component: () => import('./pages/images/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/images/view/:id', 
				name: 'imagesview', 
				component: () => import('./pages/images/view.vue'), 
				props: true
			},
		
			{ 
				path: '/images/add', 
				name: 'imagesadd', 
				component: () => import('./pages/images/add.vue'), 
				props: true
			},
	
			{ 
				path: '/images/edit/:id', 
				name: 'imagesedit', 
				component: () => import('./pages/images/edit.vue'), 
				props: true
			},
		

//documenttypes routes
			{
				path: '/documenttypes/:fieldName?/:fieldValue?',
				name: 'documenttypeslist',
				component: () => import('./pages/documenttypes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/documenttypes/view/:id', 
				name: 'documenttypesview', 
				component: () => import('./pages/documenttypes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/documenttypes/add', 
				name: 'documenttypesadd', 
				component: () => import('./pages/documenttypes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/documenttypes/edit/:id', 
				name: 'documenttypesedit', 
				component: () => import('./pages/documenttypes/edit.vue'), 
				props: true
			},
		

//expenses routes
			{
				path: '/expenses/:fieldName?/:fieldValue?',
				name: 'expenseslist',
				component: () => import('./pages/expenses/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/expenses/view/:id', 
				name: 'expensesview', 
				component: () => import('./pages/expenses/view.vue'), 
				props: true
			},
		
			{ 
				path: '/expenses/add', 
				name: 'expensesadd', 
				component: () => import('./pages/expenses/add.vue'), 
				props: true
			},
	
			{ 
				path: '/expenses/edit/:id', 
				name: 'expensesedit', 
				component: () => import('./pages/expenses/edit.vue'), 
				props: true
			},
		

//paymentmethods routes
			{
				path: '/paymentmethods/:fieldName?/:fieldValue?',
				name: 'paymentmethodslist',
				component: () => import('./pages/paymentmethods/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/paymentmethods/view/:id', 
				name: 'paymentmethodsview', 
				component: () => import('./pages/paymentmethods/view.vue'), 
				props: true
			},
		
			{ 
				path: '/paymentmethods/add', 
				name: 'paymentmethodsadd', 
				component: () => import('./pages/paymentmethods/add.vue'), 
				props: true
			},
	
			{ 
				path: '/paymentmethods/edit/:id', 
				name: 'paymentmethodsedit', 
				component: () => import('./pages/paymentmethods/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//projects routes
			{
				path: '/projects/:fieldName?/:fieldValue?',
				name: 'projectslist',
				component: () => import('./pages/projects/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/projects/view/:id', 
				name: 'projectsview', 
				component: () => import('./pages/projects/view.vue'), 
				props: true
			},
		
			{ 
				path: '/projects/add', 
				name: 'projectsadd', 
				component: () => import('./pages/projects/add.vue'), 
				props: true
			},
	
			{ 
				path: '/projects/edit/:id', 
				name: 'projectsedit', 
				component: () => import('./pages/projects/edit.vue'), 
				props: true
			},
		

//projectstatus routes
			{
				path: '/projectstatus/:fieldName?/:fieldValue?',
				name: 'projectstatuslist',
				component: () => import('./pages/projectstatus/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/projectstatus/view/:id', 
				name: 'projectstatusview', 
				component: () => import('./pages/projectstatus/view.vue'), 
				props: true
			},
		
			{ 
				path: '/projectstatus/add', 
				name: 'projectstatusadd', 
				component: () => import('./pages/projectstatus/add.vue'), 
				props: true
			},
	
			{ 
				path: '/projectstatus/edit/:id', 
				name: 'projectstatusedit', 
				component: () => import('./pages/projectstatus/edit.vue'), 
				props: true
			},
		

//projectusers routes
			{
				path: '/projectusers/:fieldName?/:fieldValue?',
				name: 'projectuserslist',
				component: () => import('./pages/projectusers/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/projectusers/view/:id', 
				name: 'projectusersview', 
				component: () => import('./pages/projectusers/view.vue'), 
				props: true
			},
		
			{ 
				path: '/projectusers/add', 
				name: 'projectusersadd', 
				component: () => import('./pages/projectusers/add.vue'), 
				props: true
			},
	
			{ 
				path: '/projectusers/edit/:id', 
				name: 'projectusersedit', 
				component: () => import('./pages/projectusers/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/projectusers/addusertoproject', 
				name: 'projectusersaddusertoproject', 
				component: () => import('./pages/projectusers/addusertoproject.vue'), 
				props: true
			},
	

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//subcategories routes
			{
				path: '/subcategories/:fieldName?/:fieldValue?',
				name: 'subcategorieslist',
				component: () => import('./pages/subcategories/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/subcategories/view/:id', 
				name: 'subcategoriesview', 
				component: () => import('./pages/subcategories/view.vue'), 
				props: true
			},
		
			{ 
				path: '/subcategories/add', 
				name: 'subcategoriesadd', 
				component: () => import('./pages/subcategories/add.vue'), 
				props: true
			},
	
			{ 
				path: '/subcategories/edit/:id', 
				name: 'subcategoriesedit', 
				component: () => import('./pages/subcategories/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/users/projectdefault/:id', 
				name: 'usersprojectdefault', 
				component: () => import('./pages/users/projectdefault.vue'), 
				props: true
			},
		

//vendors routes
			{
				path: '/vendors/:fieldName?/:fieldValue?',
				name: 'vendorslist',
				component: () => import('./pages/vendors/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vendors/view/:id', 
				name: 'vendorsview', 
				component: () => import('./pages/vendors/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vendors/add', 
				name: 'vendorsadd', 
				component: () => import('./pages/vendors/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors/edit/:id', 
				name: 'vendorsedit', 
				component: () => import('./pages/vendors/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/respasswordet', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
