
export const AppMenus = {
  navbarTopRight: [],
  navbarTopLeft: [],
  navbarSideLeft: [
    {
      to: '/home',
      label: 'Inicio',
      icon: 'pi pi-home text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/expenses',
      label: 'Gastos',
      icon: 'pi pi-money-bill text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/budgets',
      label: 'Presupuesto',
      icon: 'pi pi-chart-line',
      iconcolor: '',
      target: '',
    },
    {
      to: '/vendors',
      label: 'Proveedores',
      icon: 'pi pi-id-card text-primary',
      iconcolor: '',
      target: '',
    },
    {
      to: '/projects',
      label: 'Proyectos',
      icon: 'pi pi-book',
      iconcolor: '',
      target: '',
      roles: ['Admin', 'Oficina'],
    },
    {
      to: '/documents',
      label: 'Documentos',
      icon: 'pi pi-file',
      iconcolor: '',
      target: '',
      roles: ['Admin', 'Oficina', 'campo'],
    },
    {
      to: '/images',
      label: 'Imagenes',
      icon: 'pi pi-image',
      iconcolor: '',
      target: '',
      roles: ['Admin', 'Oficina', 'campo'],
    },
    {
      to: '',
      label: 'Administración',
      icon: 'pi pi-th-large text-primary',
      iconcolor: '',
      target: '',
      roles: ['Admin'],
      items: [
        {
          to: '/categories',
          label: 'Categorias',
          icon: 'pi pi-bars',
          iconcolor: '',
          target: '',
        },
        {
          to: '/subcategories',
          label: 'Subcategorias',
          icon: 'pi pi-list',
          iconcolor: '',
          target: '',
        },
        {
          to: '/projectstatus',
          label: 'Estatus del proyecto',
          icon: 'pi pi-circle',
          iconcolor: '',
          target: '',
        },
        {
          to: '/paymentmethods',
          label: 'Métodos de pago',
          icon: 'pi pi-credit-card',
          iconcolor: '',
          target: '',
        },
        {
          to: '/users',
          label: 'Usuarios',
          icon: 'pi pi-users',
          iconcolor: '',
          target: '',
        },
        {
          to: '/roles',
          label: 'Roles',
          icon: 'pi pi-user-plus',
          iconcolor: '',
          target: '',
        },
        {
          to: '/permissions',
          label: 'Permisos',
          icon: 'pi pi-key',
          iconcolor: '',
          target: '',
        },

        {
          to: '/documenttypes',
          label: 'Documenttypes',
          icon: 'pi pi-copy',
          iconcolor: '',
          target: '',
        },
      ],
    },
  ],
  isApproved: [{ value: '1', label: 'Aprobado' }],
  isClosed: [
    { value: '1', label: 'Abierto' }
  ],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: 'print',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },
};