export default {
	startsWith: 'Inizia con',
	contains: 'Contiene',
	notContains: 'Non contiene',
	endsWith: 'Finisce con',
	equals: 'Uguale',
	notEquals: 'Non uguale',
	noFilter: 'Nessun filtro',
	lt: 'Meno di',
	lte: 'Inferiore o uguale a',
	gt: 'Maggiore di',
	gte: 'Maggiore o uguale a',
	dateIs: 'La data è',
	dateIsNot: 'La data non è',
	dateBefore: 'La data è precedente',
	dateAfter: 'La data è successiva',
	clear: 'Cancella',
	apply: 'Applica',
	matchAll: 'Abbina tutto',
	matchAny: 'Abbina qualsiasi',
	addRule: 'Aggiungi regola',
	removeRule: 'Rimuovi regola',
	accept: 'Sì',
	reject: 'No',
	choose: 'Scegli',
	upload: 'Carica',
	cancel: 'Annulla',
	dayNames: ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'],
	dayNamesShort: ['Sun', 'Lun', 'Mar', 'Wed', 'Thu', 'Fri', 'Sat'],
	dayNamesMin: ['Dom', 'Mo', 'Mar', 'We', 'Th', 'Fr', 'Sa'],
	monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
	monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	today: 'Oggi',
	weekHeader: 'Settimana',
	firstDayOfWeek: 0,
	dateFormat: 'yy-mm-dd',
	weak: 'Debole',
	medium: 'Medio',
	strong: 'Forte',
	passwordPrompt: 'Inserisci una password',
	emptyFilterMessage: 'Nessun risultato trovato',
	emptyMessage: 'Nessuna opzione disponibile',
	uploadedFiles: 'Uploaded files',
	uploadFailed: 'Upload Failed',
	maxFileLimit: 'Max file limit of ${limit} execeeded!',
	aria: {
		trueLabel: "True",
		falseLabel: "False",
		nullLabel: "Not Selected",
		pageLabel: "Page",
		firstPageLabel: "First Page",
		lastPageLabel: "Last Page",
		nextPageLabel: "Next Page",
		previousPageLabel: "Previous Page",
		selectLabel: "Select",
		unselectLabel: "Unselect",
		expandLabel: "Expand",
		collapseLabel: "Collapse"
	},
	validations: {
		required: "Il valore è obbligatorio.",
		undefined: "Il valore è obbligatorio.",
		email: "Il valore non è un indirizzo email valido",
		minLength: "Questo campo dovrebbe essere lungo almeno ${min} caratteri",
		maxLength: "La lunghezza massima consentita è ${max}",
		minValue: "Il valore minimo consentito è ${min}",
		maxValue: "Il valore massimo consentito è ${max}",
		numeric: "Il valore deve essere numerico",
		ipAddress: "Il valore non è un indirizzo IP valido",
		sameAs: "Il valore deve essere uguale al valore ${otherName}",
	}
}