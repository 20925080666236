export default {
	id: 'Folio',
	startsWith: 'Empieza con',
	contains: 'Contiene',
	notContains: 'No incluye',
	endsWith: 'Termina con',
	equals: 'igual',
	notEquals: 'no es igual',
	noFilter: 'Sin filtro',
	lt: 'Menos que',
	lte: 'Menos que o igual a',
	gt: 'Mayor que',
	gte: 'Mayor qué o igual a',
	dateIs: 'Fecha es',
	dateIsNot: 'Fecha no es',
	dateBefore: 'La fecha es antes',
	dateAfter: 'La fecha es despues',
	clear: 'Limpiar',
	apply: 'Aplicar',
	matchAll: 'Coincidir con todo',
	matchAny: 'Coincide con cualquiera',
	addRule: 'Añadir Regla',
	removeRule: 'Quitar Regla',
	accept: 'Sí',
	reject: 'No',
	choose: 'Escoger',
	upload: 'Subir',
	cancel: 'Cancelar',
	dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
	dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
	dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
	monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
	monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
	today: 'Hoy dia',
	weekHeader: 'Semana',
	firstDayOfWeek: 1,
	dateFormat: 'dd-mm-yy',
	weak: 'Débil',
	medium: 'Mediano',
	strong: 'Fuerte',
	passwordPrompt: 'Introduce una contraseña',
	emptyFilterMessage: 'No se han encontrado resultados',
	emptyMessage: 'Sin opciones disponibles',
	uploadedFiles: 'Uploaded files',
	uploadFailed: 'Upload Failed',
	maxFileLimit: 'Max file limit of ${limit} execeeded!',
	aria: {
		trueLabel: "Verdadero",
		falseLabel: "Falso",
		nullLabel: "No seleccionado",
		pageLabel: "PûÀgina",
		firstPageLabel: "Primera PûÀgina",
		lastPageLabel: "û?ltima PûÀgina",
		nextPageLabel: "PûÀgina Siguiente",
		previousPageLabel: "PûÀgina Anterior",
		selectLabel: "Seleccione",
		unselectLabel: "Deseleccionar",
		expandLabel: "Expandir",
		collapseLabel: "Contraer"
	},
	validations: {
		required: "El valor es requerido.",
		undefined: "El valor es requerido.",
		email: "El valor no es una dirección de correo electrónico válida",
		minLength: "Este campo debe tener al menos ${min} caracteres",
		maxLength: "La longitud máxima permitida es ${max}",
		minValue: "El valor mínimo permitido es ${min}",
		maxValue: "El valor máximo permitido es ${max}",
		numeric: "El valor debe ser numérico",
		ipAddress: "El valor no es una dirección IP válida",
		sameAs: "El valor debe ser igual al valor de ${otherName}",
	}
}