export default {
	startsWith: 'Начинается с',
	contains: 'содержит',
	notContains: 'Не содержит',
	endsWith: 'Заканчивается на',
	equals: 'Равно',
	notEquals: 'Не равно',
	noFilter: 'Нет фильтра',
	lt: 'Меньше, чем',
	lte: 'Меньше чем или равно',
	gt: 'Больше чем',
	gte: 'Больше или равно',
	dateIs: 'Дата',
	dateIsNot: 'Дата не',
	dateBefore: 'Дата предшествует',
	dateAfter: 'Дата после',
	clear: 'ясно',
	apply: 'применять',
	matchAll: 'Сопоставить все',
	matchAny: 'Соответствие любому',
	addRule: 'Добавить правило',
	removeRule: 'Удалить правило',
	accept: 'Да',
	reject: 'Нет',
	choose: 'Выбирать',
	upload: 'Закачать',
	cancel: 'Отмена',
	dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Субота'],
	dayNamesShort: ['Воск', 'Пон', 'Вт', 'Ср', 'Четв', 'Пят', 'Суб'],
	dayNamesMin: ['В', 'П', 'Вт', 'С ', 'Ч', 'П ', 'Сб'],
	monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
	monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
	today: 'Сегодня',
	weekHeader: 'Неделя',
	firstDayOfWeek: 1,
	dateFormat: 'yy/mm/dd',
	weak: 'Слабый',
	medium: 'Средняя',
	strong: 'Сильный',
	passwordPrompt: 'введите пароль',
	emptyFilterMessage: 'Не найдено результатов',
	emptyMessage: 'Нет доступных вариантов',
	uploadedFiles: 'Uploaded files',
	uploadFailed: 'Upload Failed',
	maxFileLimit: 'Max file limit of ${limit} execeeded!',
	aria: {
		trueLabel: "Верно",
		falseLabel: "Неверно",
		nullLabel: "Не выбран",
		pageLabel: "Страница",
		firstPageLabel: "Первая страница",
		lastPageLabel: "Последняя страница",
		nextPageLabel: "Следующая страница",
		previousPageLabel: "Предыдущая страница"
	},
	validations: {
		required: "Требуется значение.",
		undefined: "Требуется значение.",
		email: "Значение не является действительным адресом электронной почты",
		minLength: "Это поле должно содержать не менее ${min} символов",
		maxLength: "Максимально допустимая длина ${max}",
		minValue: "Минимальное допустимое значение: ${min}",
		maxValue: "Максимально допустимое значение: ${max}",
		numeric: "Значение должно быть числовым",
		ipAddress: "Значение не является действительным IP-адресом",
		sameAs: "Значение должно быть равно значению ${otherName}",
	}
}